import React from 'react';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { findUserShowRatingByListingId, getUserShowRatings } from '../../util/data';
import { useElementLoaded, useElementSize } from '../../util/hooks';
import {
  ResponsiveImage,
  Modal,
  ImageCarousel,
  NamedLink,
  InlineTextButton,
  IconShare,
  IconEdit,
  IconReviewStar,
  IconGallery,
  ExpandableBio,
  ButtonWatchlist,
  AspectRatioWrapper,
} from '../../components';
import classNames from 'classnames';

import css from './ListingPage.module.css';

const MAX_AVERAGE_REVIEW = 10;
const DESCRIPTION_MAX_LENGTH = 130;
const DESCRIPTION_MAX_LENGTH_MOBILE = 60;

const getPosterImage = (listing, posterImageId, firstImage) => {
  const posterImage = listing?.images?.find(i => i.id.uuid === posterImageId) ?? firstImage;
  return posterImage;
};

const SectionImages = props => {
  const {
    title,
    richTitle,
    listing,
    currentUser,
    currentAuthor,
    authorDisplayName,
    reviews,
    averageReview,
    organizationProfile,
    organizationProfiles,
    posterImageId,
    location,
    isOwnListing,
    isMobileLayout,
    isTabletLayout,
    imageCarouselOpen,
    editParams,
    handleViewPhotosClick,
    onImageCarouselClose,
    onOpenShareModal,
    onOpenShowRatingModal,
    onOpenDescriptionModal,
    onManageDisableScrolling,
  } = props;

  // hooks
  const { elementRef, elementWidth } = useElementSize();
  const posterImageLoading = useElementLoaded();

  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;

  const posterImage = getPosterImage(listing, posterImageId, firstImage);

  const descriptionWidthMaybe =
    isTabletLayout || isMobileLayout
      ? {}
      : {
          style: {
            width: elementWidth,
          },
        };

  const isDraft = listing.attributes.state === LISTING_STATE_DRAFT;
  const editMessageId = isDraft ? 'ListingPage.finishListing' : 'ListingPage.editListing';

  const isPartOfOrganization = !!organizationProfiles?.find(p => p?.id === organizationProfile?.id);
  const canEditListing = isOwnListing || isPartOfOrganization;

  const showRatings = getUserShowRatings(currentUser);
  const showRating = findUserShowRatingByListingId(showRatings, listing.id.uuid);
  const subscriptions = listing?.attributes?.metadata?.subscriptions?.length || 0;

  // title container
  const titleContainer = (
    <div className={css.titleContainer}>
      {organizationProfile?.id ? (
        <NamedLink
          className={css.organizationProfile}
          name="OrganizationProfilePage"
          params={{ id: organizationProfile.id }}
        >
          {organizationProfile.name}
        </NamedLink>
      ) : (
        <span className={css.organizationProfileLoadingText}>
          <FormattedMessage id="ListingPage.loadingOrganizationProfileText" />
        </span>
      )}
      <div className={css.listingData}>
        <div className={css.listingDataMainInfo}>
          <div className={css.listingImageMobile}>
            <AspectRatioWrapper width={5} height={7}>
              <ResponsiveImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={['poster-crop']}
              />
            </AspectRatioWrapper>
          </div>
          <div className={css.listingDataMainInfoColumn}>
            <h1 className={css.title}>{richTitle}</h1>
            <div className={css.topDescriptionWrapper} {...descriptionWidthMaybe}>
              <ExpandableBio
                className={css.topDescription}
                showMoreClassName={css.topDescriptionShowMore}
                bio={listing?.attributes?.description}
                maxLength={isMobileLayout ? DESCRIPTION_MAX_LENGTH_MOBILE : DESCRIPTION_MAX_LENGTH}
                onReadMore={onOpenDescriptionModal}
              />
            </div>
          </div>
        </div>

        <div className={css.topReviews} ref={elementRef}>
          <div className={css.topReviewsSection}>
            <span className={css.topReviewsHeading}>
              <FormattedMessage id="ListingPage.rating" />
            </span>
            <span className={css.topReviewsValue}>
              <IconReviewStar isFilled />
              <FormattedMessage
                id="ListingPage.ratingCount"
                values={{ count: averageReview, maxCount: MAX_AVERAGE_REVIEW }}
              />
            </span>
          </div>
          <div className={css.topReviewsSection}>
            <span className={css.topReviewsHeading}>
              <FormattedMessage id="ListingPage.yourRating" />
            </span>
            {showRating ? (
              <InlineTextButton
                type="button"
                className={css.topReviewsValue}
                onClick={onOpenShowRatingModal}
              >
                <IconReviewStar className={css.showRatingStar} isFilled />
                <FormattedMessage
                  id="ListingPage.ratingCount"
                  values={{ count: showRating.rating, maxCount: MAX_AVERAGE_REVIEW }}
                />
              </InlineTextButton>
            ) : (
              <InlineTextButton
                type="button"
                className={css.topReviewsValue}
                onClick={onOpenShowRatingModal}
              >
                <IconReviewStar />
                <FormattedMessage id="ListingPage.rateText" />
              </InlineTextButton>
            )}
          </div>
          <div className={css.topReviewsSection}>
            <span className={css.topReviewsHeading}>
              <FormattedMessage id="ListingPage.userReviews" />
            </span>
            <span className={css.topReviewsValue}>{reviews.length}</span>
          </div>
          <div className={css.topReviewsSection}>
            <span className={css.topReviewsHeading}>
              <FormattedMessage id="ListingPage.members" />
            </span>
            <span className={css.topReviewsValue}>{subscriptions}</span>
          </div>
        </div>
      </div>
    </div>
  );

  // view photos button
  const viewPhotosButton = hasImages ? (
    <InlineTextButton className={css.viewPhotos} onClick={handleViewPhotosClick}>
      <IconGallery className={css.galleryIcon} />
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </InlineTextButton>
  ) : null;

  // author info container
  const authorInfo = (
    <div className={css.author}>
      {hasImages ? (
        viewPhotosButton
      ) : currentAuthor?.id ? (
        <NamedLink
          className={css.authorHeading}
          name="ProfilePage"
          params={{ id: currentAuthor?.id?.uuid }}
        >
          {authorDisplayName}
        </NamedLink>
      ) : null}
      <div className={css.authorActions}>
        <InlineTextButton className={css.authorAction} onClick={onOpenShareModal}>
          <IconShare className={css.authorActionIcon} />
          <FormattedMessage id="ListingPage.shareButton" />
        </InlineTextButton>
        {canEditListing ? (
          <NamedLink
            className={css.authorAction}
            name="EditListingPage"
            params={editParams}
            to={{
              state: {
                from: `${location.pathname}${location.search}${location.hash}`,
              },
            }}
          >
            <IconEdit className={classNames(css.authorActionIcon, css.editIcon)} />
            <FormattedMessage id={editMessageId} />
          </NamedLink>
        ) : null}
      </div>
    </div>
  );

  const sectionProps = {
    className: css.sectionImages,
  };

  return (
    <div {...sectionProps}>
      <div className={css.heroContentWrapper}>
        <div className={css.heroContent}>
          {authorInfo}
          <div className={css.listingInfo}>
            <div className={css.listingImage}>
              <AspectRatioWrapper
                className={css.listingImageAspectRatioWrapper}
                width={5}
                height={7}
              >
                <ResponsiveImage
                  rootClassName={css.listingImageRoot}
                  alt={title}
                  image={posterImage}
                  variants={['portrait-crop2x', 'portrait-crop4x']}
                />
                <ButtonWatchlist listing={listing} />
              </AspectRatioWrapper>
            </div>
            {titleContainer}
          </div>
        </div>
        <Modal
          id="ListingPage.imageCarousel"
          scrollLayerClassName={css.carouselModalScrollLayer}
          containerClassName={css.carouselModalContainer}
          lightCloseButton
          isOpen={imageCarouselOpen}
          onClose={onImageCarouselClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <ImageCarousel
            images={listing.images}
            imageVariants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
          />
        </Modal>
      </div>
    </div>
  );
};

export default SectionImages;
