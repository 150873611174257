import React from 'react';
import { Slider, VideoPlayer } from '../../components';
import classNames from 'classnames';

import css from './ListingPage.module.css';

// slidesToShow
const SLIDES_TO_SHOW_TABLET = 3;
const SLIDES_TO_SHOW_DESKTOP = 3.5;
// slidesToScroll
const SLIDES_TO_SCROLL_TABLET = 3;
const SLIDES_TO_SCROLL_DESKTOP = 1.5;

// VideoPlayer settings
const VIDEO_PLAYER_SETTINGS = {
  bigPlayButton: true,
};

const SectionHighlightsMaybe = props => {
  const { intl, publicData, isMobileLayout } = props;

  const videos = publicData?.videos || [];
  const sliderTitle = intl.formatMessage(
    { id: 'ListingPage.highlightsTitle' },
    { count: videos.length }
  );

  return (
    <div
      className={classNames(css.sectionHighlights, {
        [css.sectionHighlightsHidden]: videos.length === 0,
      })}
    >
      <div className={css.highlights}>
        <Slider
          id="SectionHighlights"
          title={sliderTitle}
          headerClassName={css.sliderHeader}
          titleClassName={css.highlightsTitle}
          itemsClassName={css.highlightsSliderItems}
          arrowsClassName={css.highlightsSliderArrows}
          // Responsive slider settings
          slidesToShow={{
            tablet: SLIDES_TO_SHOW_TABLET,
            desktop: SLIDES_TO_SHOW_DESKTOP,
          }}
          slidesToScroll={{
            tablet: SLIDES_TO_SCROLL_TABLET,
            desktop: SLIDES_TO_SCROLL_DESKTOP,
          }}
        >
          {videos.map(video => {
            return (
              <VideoPlayer
                key={video.id}
                controlTextClassName={css.videoPlayerControlText}
                video={video}
                settings={{ ...VIDEO_PLAYER_SETTINGS, playerTime: !isMobileLayout }}
              />
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default SectionHighlightsMaybe;
