import React from 'react';
import { Modal, NamedLink, ExternalLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureHttps } from '../../util/data';

import css from './ListingPage.module.css';

const DetailItem = props => {
  const { detailHeading, detailValue, linkParams, externalLinkParams } = props;
  return (
    <div className={css.detail}>
      <span className={css.detailHeading}>{detailHeading}</span>
      {linkParams ? (
        <NamedLink className={css.detailValue} {...linkParams}>
          {detailValue}
        </NamedLink>
      ) : externalLinkParams ? (
        <ExternalLink {...externalLinkParams}>{detailValue}</ExternalLink>
      ) : (
        <span className={css.detailValue}>{detailValue}</span>
      )}
    </div>
  );
};

const DescriptionDetails = props => {
  const { listingFields, organizationProfile } = props;

  if (!listingFields.launch && !listingFields.countryOfOrigin && !organizationProfile) {
    return null;
  }

  return (
    <div className={css.descriptionDetails}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </h2>
      <div className={css.details}>
        {listingFields.launch ? (
          <DetailItem detailHeading="Launch" detailValue={listingFields.launch} />
        ) : null}
        {listingFields.countryOfOrigin ? (
          <DetailItem
            detailHeading="Country of origin"
            detailValue={listingFields.countryOfOrigin}
          />
        ) : null}
        {organizationProfile && (
          <DetailItem
            detailHeading="Production company"
            detailValue={organizationProfile.name}
            linkParams={{
              name: 'OrganizationProfilePage',
              params: { id: organizationProfile.id },
            }}
          />
        )}
        {listingFields.website ? (
          <DetailItem
            detailHeading="Website"
            detailValue={listingFields.website}
            externalLinkParams={{
              href: ensureHttps(listingFields.website),
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

const DescriptionModal = props => {
  const {
    containerClassName,
    contentClassName,
    id,
    isOpen,
    description,
    listingTitle,
    listingFields,
    organizationProfile,
    onCloseModal,
    onManageDisableScrolling,
  } = props;

  return (
    <Modal
      id={id}
      containerClassName={containerClassName}
      contentClassName={contentClassName}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <h1 className={css.modalTitle}>
        <FormattedMessage id="ListingPage.descriptionModalTitle" values={{ title: listingTitle }} />
      </h1>
      <p className={css.modalMessage}>{description}</p>
      <DescriptionDetails listingFields={listingFields} organizationProfile={organizationProfile} />
    </Modal>
  );
};

export default DescriptionModal;
