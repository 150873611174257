import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { createSlug } from '../../util/urlHelpers';
import { NamedLink, HostCard, IconArrowHead } from '../../components';
import config from '../../config';

import css from './ListingPage.module.css';

const MAX_VISIBLE_HOSTS = 12;

const SectionHostsMaybe = props => {
  const { listing, users, fetchUsersInProgress, fetchUsersError, teamFromPublicData } = props;

  if (fetchUsersInProgress || users.length === 0) {
    return null;
  }

  const fetchError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ListingPage.fetchHostsError" />
    </h2>
  );

  const hostsTitle =
    users.length > MAX_VISIBLE_HOSTS ? (
      listing.id ? (
        <NamedLink
          className={css.hostsTitle}
          name="HostsPage"
          params={{
            id: listing.id.uuid,
            slug: createSlug(listing.attributes.title),
            type: config.listingTypes['show'],
          }}
        >
          <FormattedMessage id="ListingPage.hostsHeading" />
          <IconArrowHead className={css.hostsHeadingIcon} direction="right" />
        </NamedLink>
      ) : null
    ) : (
      <h2 className={css.hostsTitle}>
        <FormattedMessage id="ListingPage.hostsHeading" />
      </h2>
    );

  return (
    <div className={css.sectionHostsMaybe}>
      {hostsTitle}
      {fetchUsersError ? fetchError : null}
      <div className={css.hosts}>
        {users.slice(0, MAX_VISIBLE_HOSTS).map(u => {
          return <HostCard key={u.id.uuid} user={u} teamFromPublicData={teamFromPublicData} />;
        })}
      </div>
    </div>
  );
};

export default SectionHostsMaybe;
