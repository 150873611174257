import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, OrganizationProfileCard, IconArrowHead } from '../../components';

import css from './ListingPage.module.css';

const SectionHostMaybe = props => {
  const { organizationProfile } = props;

  if (!organizationProfile) {
    return null;
  }

  return (
    <div className={css.sectionHostMaybe}>
      <NamedLink
        className={css.yourHostHeading}
        name="OrganizationProfilePage"
        params={{
          id: organizationProfile.id,
        }}
      >
        <FormattedMessage
          id="ListingPage.yourHostHeading"
          values={{
            title: organizationProfile.name,
          }}
        />
        <IconArrowHead className={css.yourHostHeadingIcon} direction="right" />
      </NamedLink>
      <OrganizationProfileCard organizationProfile={organizationProfile} />
    </div>
  );
};

export default SectionHostMaybe;
