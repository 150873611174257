import React from 'react';
import { Modal, IconClap } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SuccessInviteModal = props => {
  const {
    containerClassName,
    contentClassName,
    id,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
  } = props;

  return (
    <Modal
      id={id}
      containerClassName={containerClassName}
      contentClassName={contentClassName}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <IconClap className={css.modalIcon} />
      <h1 className={css.modalTitle}>
        <FormattedMessage id="ListingPage.successInviteModalTitle" />
      </h1>
      <p className={css.modalMessage}>
        <FormattedMessage id="ListingPage.successInviteModalMessage" />
      </p>
    </Modal>
  );
};

export default SuccessInviteModal;
