import React from 'react';
import { Modal, NamedLink, IconClap } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SuccessPostingModal = props => {
  const {
    containerClassName,
    contentClassName,
    id,
    listingId,
    location,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
  } = props;
  // Edit params for creating a new experience page.
  const editParams = {
    slug: 'draft',
    id: '00000000-0000-0000-0000-000000000000',
    type: 'new',
    tab: 'details',
  };
  return (
    <Modal
      id={id}
      containerClassName={containerClassName}
      contentClassName={contentClassName}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <IconClap className={css.modalIcon} />
      <h1 className={css.modalTitle}>
        <FormattedMessage id="ListingPage.successPostingModalTitle" />
      </h1>
      <p className={css.modalMessage}>
        <FormattedMessage id="ListingPage.successPostingModalMessage" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ListingPage.successPostingModalSecondMessage" />
      </p>
      <NamedLink
        className={css.modalButton}
        name="EditExperiencePage"
        params={editParams}
        to={{
          state: {
            from: `${location.pathname}${location.search}${location.hash}`,
            showId: listingId.uuid,
          },
        }}
      >
        <FormattedMessage id="ListingPage.successPostingModalButtonText" />
      </NamedLink>
    </Modal>
  );
};

export default SuccessPostingModal;
